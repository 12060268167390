<script>
import Vue from "vue";
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import {downFile} from '@/apis/common'
import {saveInvoice,invoiceDetail,orderDetail,invoiceEmail,setInvoiceStatus,createInvoiceFile} from '@/apis/order'
import {
  required
} from "vuelidate/lib/validators";
/**
 * Form Repeater component
 */
export default {
  page: {
    title: "Invoice",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout},
  validations: {
    form: {
      name: { 
            required,
      },
      taxNumber: { 
            required,
      },
      address: { 
            required,
      },
      phoneNumber: { 
            required,
      },
      bank: { 
            required,
      },
      accountNumber: { 
            required,
      },
      receivingName: { 
            required,
      },
      receivingAddress: { 
            required,
      },
      receivingMobile: { 
            required,
      },
    },
  },
  data() {
    return {
      title: "Invoice",
      orderId:null,
      order:null,
      clientId:null,
      historyInvoice:null,
      form:{
        name:"",
        taxNumber:"",
        address:"",
        phoneNumber:"",
        bank:"",
        accountNumber:"",
        attachment:"",
        receivingName:"",
        receivingAddress:"",
        receivingMobile:"",
        trackingNumber:"",
        clientId:null,
        orderNo:null
      },
      submitted:false,
    };
  },
  created(){
    if(this.$route.query.clientId!=undefined){
      this.clientId = this.$route.query.clientId;
    }
    if(this.$route.query.orderNo!=undefined){
      this.orderNo = this.$route.query.orderNo;
    }
    this.init();
  },
  mounted() {
  },
  computed:{
    invoiceAction(){
      if(this.order!=null && this.order.invoiceStatus=='N'){
        return true
      }
      return false;
    },
    hisAction(){
      if(this.historyInvoice!=null&&this.order!=null && this.order.invoiceStatus=='N'){
        return true
      }
      return false;
    },
    showFollowupButton(){
      return true;
    },
  },
  watch:{
  },
  methods: {
    async init(){
      // 订单数据载入
      if(this.orderNo!=null){
        invoiceDetail(this.orderNo,this.clientId).then((res)=>{
          this.form = res.data
          if(res.data.historyInvoice!=null){
            this.historyInvoice = res.data.historyInvoice
            this.historyInvoice.invoiceId = null
            this.historyInvoice.trackingNumber = ""
          }
        }).catch(()=>{
          Swal.fire("System Error", this.$t("order.errorRequest"), "error").then(()=>{
          });
        })
        orderDetail(this.orderNo).then((res)=>{
          this.order = res.data
        }).catch((res)=>{
          if(res.message=='ERR_NO_EXIST'){
            Swal.fire("System Error",this.$t("order.errorOrderExits"), "error").then(()=>{
              this.$router.go(-1)
            });
          }else{
            Swal.fire("System Error", this.$t("order.errorRequest"), "error").then(()=>{
              this.$router.go(-1)
            });
          }
        })
      }
    },
    handleSave() {
      this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        } else {
          let params = {...this.form}   
          params.clientId = this.clientId
          params.orderNo = this.orderNo
          params.userUpdate = false
          saveInvoice(params).then(()=>{
            Vue.swal(this.$t("invoice.infoUpdated"));
            this.init();
          })
        }
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleDownload (item){
        const url=`/api-gateway/service/download?fileType=QT&fileName=${item.quotationFile}`
        downFile(url,item.fileName);
    },
    handleLoadHistory(){
      this.form =  {...this.historyInvoice}
    },
    handleInvoiceEmail(){
      invoiceEmail(this.orderNo).then((res)=>{
        if(res.data){
          Vue.swal(this.$t("invoice.infoEmail"));
        }
      });
    },
    handleDownloadInvoiceTable(){
      createInvoiceFile(this.orderNo,this.clientId).then((res)=>{
        if(res.data){
          const url=`/api-gateway/service/download?fileType=INV&fileName=${res.data}`
          downFile(url,"");
        }
      });
      
    },
    handleSetInvoiceStatus(){
      setInvoiceStatus(this.orderNo).then((res)=>{
        if(res.data){
          Vue.swal(this.$t("invoice.infoStatus"));
          this.init();
        }
      });
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row  mt-4">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="card-title mb-4">{{$t("invoice.title")}}</h5>
              </div>
              <div class="col-sm-6">
                <div class="float-end d-none d-md-block">
                  <a href="javascript:"  class="btn btn-primary" v-if="invoiceAction" @click="handleInvoiceEmail">{{$t("invoice.sendEmail")}}</a>
                  <a href="javascript:"  class="btn btn-primary ms-1" v-if="invoiceAction" @click="handleDownloadInvoiceTable">{{$t("invoice.export")}}</a>
                  <a href="javascript:"  class="btn btn-info ms-1" v-if="invoiceAction" @click="handleSetInvoiceStatus">{{$t("invoice.status")}}</a>
                  <a href="javascript:"  class="btn btn-success ms-1" v-if="hisAction" @click="handleLoadHistory">{{$t("invoice.loadHistory")}}</a>
                  <a href="javascript:" @click="handleBack" class="btn btn-light ms-1">{{$t("actions.back")}}</a>
                </div>
              </div>
            </div>
            <form class="needs-validation">
                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    01
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">{{$t("invoice.title")}}</h5>
                        </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                          <div class="col-lg-6 mb-3">
                                  <label class="form-label" for="gen-info-name-input">{{$t("invoice.companyName")}}</label>
                                  <input type="text" class="form-control" id="gen-info-name-input" v-model="form.name"
                                  :class="{
                                      'is-invalid': submitted && $v.form.name.$error,
                                  }">
                                  <div
                                      v-if="submitted && $v.form.name.$error"
                                      class="invalid-feedback"
                                      >
                                          <span v-if="!$v.form.name.required">
                                            {{$t("invoice.errorCompanyName")}}.
                                          </span>
                                  </div>
                          </div>
                          <div class="col-lg-6 mb-3">
                                  <label class="form-label" for="gen-info-taxNumber-input">{{$t("invoice.taxNumber")}}</label>
                                  <input type="text" class="form-control" id="gen-info-taxNumber-input" v-model="form.taxNumber"
                                  :class="{
                                      'is-invalid': submitted && $v.form.taxNumber.$error,
                                  }">
                                  <div
                                      v-if="submitted && $v.form.taxNumber.$error"
                                      class="invalid-feedback"
                                      >
                                          <span v-if="!$v.form.taxNumber.required">
                                            {{$t("invoice.errorTaxNumber")}}.
                                          </span>
                                  </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-6 mb-3">
                                  <label class="form-label" for="gen-info-address-input">{{$t("invoice.address")}}</label>
                                  <input type="text" class="form-control" id="gen-info-address-input" v-model="form.address"
                                  :class="{
                                      'is-invalid': submitted && $v.form.address.$error,
                                  }">
                                  <div
                                      v-if="submitted && $v.form.address.$error"
                                      class="invalid-feedback"
                                      >
                                          <span v-if="!$v.form.address.required">
                                            {{$t("invoice.errorAddress")}}.
                                          </span>
                                  </div>
                          </div>
                          <div class="col-lg-6 mb-3">
                                  <label class="form-label" for="gen-info-phoneNumber-input">{{$t("invoice.phoneNumber")}}</label>
                                  <input type="text" class="form-control" id="gen-info-phoneNumber-input" v-model="form.phoneNumber"
                                  :class="{
                                      'is-invalid': submitted && $v.form.phoneNumber.$error,
                                  }">
                                  <div
                                      v-if="submitted && $v.form.phoneNumber.$error"
                                      class="invalid-feedback"
                                      >
                                          <span v-if="!$v.form.phoneNumber.required">
                                            {{$t("invoice.errorPhone")}}.
                                          </span>
                                  </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-6 mb-3">
                                  <label class="form-label" for="gen-info-bank-input">{{$t("invoice.bank")}}</label>
                                  <input type="text" class="form-control" id="gen-info-bank-input" v-model="form.bank"
                                  :class="{
                                      'is-invalid': submitted && $v.form.bank.$error,
                                  }">
                                  <div
                                      v-if="submitted && $v.form.bank.$error"
                                      class="invalid-feedback"
                                      >
                                          <span v-if="!$v.form.bank.required">
                                            {{$t("invoice.errorBank")}}.
                                          </span>
                                  </div>
                          </div>
                          <div class="col-lg-6 mb-3">
                                  <label class="form-label" for="gen-info-accountNumber-input">{{$t("invoice.accountNumber")}}</label>
                                  <input type="text" class="form-control" id="gen-info-accountNumber-input" v-model="form.accountNumber"
                                  :class="{
                                      'is-invalid': submitted && $v.form.accountNumber.$error,
                                  }">
                                  <div
                                      v-if="submitted && $v.form.accountNumber.$error"
                                      class="invalid-feedback"
                                      >
                                          <span v-if="!$v.form.accountNumber.required">
                                            {{$t("invoice.errorAccount")}}.
                                          </span>
                                  </div>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    02
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">{{$t("invoice.express")}}</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 mb-3">
                                    <label class="form-label" for="gen-info-receivingName-input">{{$t("invoice.recipient")}}</label>
                                    <input type="text" class="form-control" id="gen-info-receivingName-input" v-model="form.receivingName"
                                    :class="{
                                        'is-invalid': submitted && $v.form.receivingName.$error,
                                    }">
                                    <div
                                        v-if="submitted && $v.form.receivingName.$error"
                                        class="invalid-feedback"
                                        >
                                            <span v-if="!$v.form.receivingName.required">
                                              {{$t("invoice.errorRecipient")}}.
                                            </span>
                                    </div>
                            </div>
                            <div class="col-lg-6 mb-3">
                                    <label class="form-label" for="gen-info-receivingMobile-input">{{$t("invoice.phoneNumber")}}</label>
                                    <input type="text" class="form-control" id="gen-info-receivingMobile-input" v-model="form.receivingMobile"
                                    :class="{
                                        'is-invalid': submitted && $v.form.receivingMobile.$error,
                                    }">
                                    <div
                                        v-if="submitted && $v.form.receivingMobile.$error"
                                        class="invalid-feedback"
                                        >
                                            <span v-if="!$v.form.receivingMobile.required">
                                              {{$t("invoice.errorPhone")}}.
                                            </span>
                                    </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 mb-3" >
                                    <label class="form-label" for="gen-info-receivingAddress-input">{{$t("invoice.address")}}</label>
                                    <input type="text" class="form-control" id="gen-info-receivingAddress-input" v-model="form.receivingAddress"
                                    :class="{
                                        'is-invalid': submitted && $v.form.receivingAddress.$error,
                                    }">
                                    <div
                                        v-if="submitted && $v.form.receivingAddress.$error"
                                        class="invalid-feedback"
                                        >
                                            <span v-if="!$v.form.receivingAddress.required">
                                              {{$t("common.addressLocalError")}}
                                            </span>
                                    </div>
                            </div>
                            <div class="col-lg-6 mb-3" >
                                <label class="form-label" for="gen-info-trackingNumber-input">{{$t("invoice.billNo")}}</label>
                                <input type="text" class="form-control" id="gen-info-trackingNumber-input" v-model="form.trackingNumber">
                            </div>
                        </div>
                    </div>
                </div>
              </form>
              <!-- end form -->
              <div class="text-end">
                  <button type="button" class="btn btn-primary w-sm" @click="handleSave" v-if="invoiceAction">{{$t("actions.submit")}}</button>
                  <button type="button" class="btn btn-success w-sm ms-1" @click="handleBack">{{$t("actions.back")}}</button>
              </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<style lang="scss">
.is-invalid .mx-input-wrapper .mx-input{
    border: 1px solid #ec4561 !important;
  }
  .is-invalid .mx-input-wrapper .mx-icon-calendar{
    color: #ec4561 !important;
  }
</style>
